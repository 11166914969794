import moment from "moment";

//hh:mm a
export const formattedHourMinuteTimeFormat = (dateTime) => {
  const utc = dateTime.split(" ").join("T");
  // const utc = utc[0] + 'T' + utc[1];
  return moment(utc).format("LT");
};
export const getHourMinuteTimeFormat = (date, time) => {
  const utc = date + "T" + time;
  return moment(utc).format("LT");
};

export const getHoursAndMinutes = (time) => {
  const date = new Date();

  date.setHours(time);
  return moment(date).format("LT");
};
// // Nov 14, 2023
export const getStandardDateFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("ll");
};
export const getMonthDateYearTimeFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("lll");
};
// Nov 14, 2023
export const getMonthDateYearFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("LL");
};

export const getDDMMYYYYFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("DD/MM/YYYY");
};
export const getDashDDMMYYYYFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("DD-MM-YYYY");
};
export const getYYYYMMDDFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("YYYY-MM-DD");
};
export const getYYYYMMDDFormatTime = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("YYYY-MM-DD , LT");
};
export const getMMMYYYYFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("MMM YYYY");
};
export const getMMMMYYYYFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("MMMM, YYYY");
};
export const getdddMMMMDDYYYYFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("ddd, MMMM D, YYYY");
};
export const getDateMiniMonthYearFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("D MMM YYYY");
};
// 12 february 2024
export const getDateMonthYearFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("D MMMM YYYY");
};
export const getDateFromImageName = (imageName) => {
  const pattern = /(\d{13})_(\d+\.\d+)-(\d+\.\d+)\.(jpg|png)/i;
  const matches = imageName.match(pattern);
  if (matches && matches.length >= 4) {
    const timestamp = parseInt(matches[1]);
    const latitude = parseFloat(matches[2]);
    const longitude = parseFloat(matches[3]);
    const location = [latitude, longitude];
    let date = null;

    if (!isNaN(timestamp)) {
      date = new Date(timestamp);
    }
    return {
      date: date ? date : null,
      latitude,
      longitude,
      location,
    };
  } else {
    return null;
  }
};

export const getAllDaysInMonth = (year, month) => {
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0);
  const dates = [];
  for (
    let date = startDate;
    date <= endDate;
    date.setDate(date.getDate() + 1)
  ) {
    dates.push(new Date(date));
  }
  return dates;
};

export const getDateMonthDayFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("DD MMM, ddd");
};
export const getDayDateFormat = (date) => {
  const utcDate = new Date(date);
  return moment(utcDate).format("ddd, DD/MM/YYYY");
};

export const dateArrayIntoDDMMMYYYYtime = (dateArray) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const date = new Date(...dateArray);

  const day = String(dateArray[2]).padStart(2, "0");
  const month = months[dateArray[1] - 1];
  const year = dateArray[0];
  let hours = dateArray[3];
  const minutes = String(dateArray[4]).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  if (hours >= 12) {
    hours = hours % 12;
  }
  if (hours === 0) {
    hours = 12;
  }
  return `${day} ${month} ${year} ${hours}:${minutes} ${period}`;
};
export const dateArrayIntoYYYYMMDDFormat = (dateArray) => {
  const day = String(dateArray[2]).padStart(2, "0");
  const month = dateArray[1];
  const year = dateArray[0];

  return `${day}/${month}/${year}`;
};
export const dateArrayIntoDayDateTimeFormat = (dateArray) => {
  // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const date = new Date(...dateArray);

  // const dayOfWeek = days[date.getDay()];
  const day = String(dateArray[2]).padStart(2, "0");
  const month = months[dateArray[1] - 1];
  const year = dateArray[0];
  // ${dayOfWeek}
  return ` ${month} ${day} ${year}`;
};

export const timeArray = (time) => {
  let hours = time[0];
  const minutes = String(time[1]).padStart(2, "0");
  const period = hours >= 12 ? "PM" : "AM";
  // Convert to 12-hour format
  if (hours >= 12) {
    hours = hours % 12;
  }

  // Ensure that 12:00 PM is displayed correctly
  if (hours === 0) {
    hours = 12;
  }
  return `${hours}:${minutes} ${period}`;
};
export const timeObject = (time) => {
  let hours = time?.hours;
  const minutes = time?.minutes;
  const period = hours >= 12 ? "PM" : "AM";
  if (hours >= 12) {
    hours = hours % 12;
  }
  if (hours === 0) {
    hours = 12;
  }
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )} ${period}`;
};
export const timeObjectHHMMSS = (time) => {
  let hours = time?.hours;
  const minutes = time?.minutes;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};
