import api from "@/services/api";
import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import VueCookies from "vue-cookies";
import { useClientStore } from "../Client/ClientStore";
import { getCapitalizeText } from "@/composables/Formatters";
import axios from "axios";

export const useJobStore = defineStore("jobStore", {
  state: () => ({
    checkJobTitleDialog: false,
    jobNotApprovedDialog: false,
    confirmationDialog: false,
    addJobDrawer: false,
    confirmationDialoge: false,
    confirmationDialoge1: false,
    changePriorityDialog: false,
    confiermUpdateOpeinigsDialog: false,
    confirmApproveJobDialog: false,
    clientStore: useClientStore(),
    user: VueCookies.get("user"),
    isLoading: false,
    titleChecked: false,
    clientAllocated: [],
    jobApprovalPending: [],
    selectedClient: "",
    selectedClientId: "",
    selectedJob: {},
    changePriorityData: {},
    search: "",
    searchJobApproval: "",
    jobDailyAllocations: [],
  }),
  actions: {
    async getJobDetails(jobId) {
      await this.clientStore.getJob(jobId);
      this.router.push({ name: "Job Details", params: { id: jobId } });
    },
    refreshJob() {
      this.getClientAllocated(this.user.id);
    },
    refreshJobApprovals() {
      this.getPendingJobApprovals();
    },
    showActionBtns(job) {
      const jobAdmin = job.allocations?.find(
        (allocation) => allocation.role.toLocaleLowerCase() === "admin"
      );
      const userId = VueCookies.get("user").id;
      return this.user?.role === "S_CSP" || userId === jobAdmin?.userId;
    },
    getJobDetailsByJobs(job) {
      if (this.showActionBtns(job)) {
        this.getJobDetails(job.id);
      }
    },
    statusChange(item, clientId) {
      this.selectedJob = item;
      this.selectedClientId = clientId;
      let formateStatus = item.status.toLocaleLowerCase();
      if (
        formateStatus === "hold" ||
        formateStatus === "closed" ||
        formateStatus === "removed"
      ) {
        this.confirmationDialoge = true;
      }
    },
    closeJobStatus(item, clientId) {
      this.selectedJob = item;
      this.selectedClientId = clientId;
    },
    driveStatusChange(item, clientId) {
      this.selectedJob = item;
      this.selectedClientId = clientId;
      this.confirmationDialoge1 = true;
    },
    openJobAction() {
      this.confirmationDialoge = false;
      this.openJob(this.selectedJob.id);
    },
    holdJobAction(data) {
      this.holdJob(this.selectedJob.id, data);
    },
    closeJobAction(data) {
      this.closeJob(this.selectedJob.id, data);
    },
    removeJobAction(data) {
      this.removeJob(this.selectedJob.id, data);
    },
    startEndDrive() {
      if (this.selectedJob.drive) {
        this.endDrive(this.selectedJob.id);
        this.confirmationDialoge1 = false;
      } else {
        this.startDrive(this.selectedJob.id);
        this.confirmationDialoge1 = false;
      }
    },
    changeOpenings(item, clientId) {
      this.selectedJob = item;
      this.selectedClientId = clientId;
    },
    updateOpeningsValue(data) {
      this.updateOpenings(this.selectedJob.id, data);
    },
    confirmChangePriority(applicationId, priority) {
      this.changePriorityData = {
        application: applicationId,
        priority: priority,
      };
      this.changePriorityDialog = true;
    },
    async changePriorityValue() {
      const allocationId = this.changePriorityData.application;
      const role = this.changePriorityData.priority;
      this.changePriorityDialog = false;
      await this.changePriority(allocationId, role);
    },
    async checkJobTitle(clientId, title) {
      if (title) {
        this.isLoading = true;
        await this.clientStore.checkJobTitle(clientId, title);
        this.titleChecked = true;
        this.isLoading = false;

        if (this.clientStore.similarData.length > 0 && this.titleChecked) {
          this.confirmationDialog = true;
          this.checkJobTitleDialog = false;
        } else {
          this.checkJobTitleDialog = false;
          this.addJobDrawer = true;
        }
        this.titleChecked = false;
      } else {
        toast.error("Title is Required", {
          autoClose: 1500,
          theme: "dark",
          position: toast.POSITION.TOP_CENTER,
          transition: toast.TRANSITIONS.ZOOM,
        });
      }
    },
    async getClientAllocated() {
      this.isLoading = true;
      await api
        .get(`/users/${this.user.id}/client-allocation`)
        .then((res) => {
          this.clientAllocated = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateJob(jobId, data) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}`, data)
        .then((res) => {
          if (res.status === 200) {
            toast.success("Job Updated successfully", {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },

    async openJob(jobId) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/open`)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const jobIndex = client.jobs.findIndex((job) => job.id === jobId);
              if (jobIndex !== -1) {
                client.jobs[jobIndex].status = "Open";
              }
            }
          }
          toast.success("Job Open successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async holdJob(jobId, data) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/hold`, data)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const jobIndex = client.jobs.findIndex((job) => job.id === jobId);
              if (jobIndex !== -1) {
                client.jobs[jobIndex].status = "Hold";
              }
            }
          }
          toast.success("Job Hold successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async closeJob(jobId, data) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/close`, data)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const job = client.jobs.findIndex((job) => job.id === jobId);
              client.jobs.splice(job, 1);
            }
          }
          toast.success("Job Closed successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async removeJob(jobId, data) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/remove`, data)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const job = client.jobs.findIndex((job) => job.id === jobId);
              client.jobs.splice(job, 1);
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async startDrive(jobId) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/start-drive`)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const jobIndex = client.jobs.findIndex((job) => job.id === jobId);
              if (jobIndex !== -1) {
                client.jobs[jobIndex].drive = true;
              }
            }
          }
          toast.success("Job drive started successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async endDrive(jobId) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/end-drive`)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const jobIndex = client.jobs.findIndex((job) => job.id === jobId);
              if (jobIndex !== -1) {
                client.jobs[jobIndex].drive = false;
              }
            }
          }

          toast.success("Job drive ended successfully", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async changePriority(jobId, priority) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/priority/${priority}`)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const jobIndex = client.jobs.findIndex((job) => job.id === jobId);
              if (jobIndex !== -1) {
                client.jobs[jobIndex].priority = getCapitalizeText(priority);
              }
            }
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async updateOpenings(jobId, noOfOpenings) {
      this.isLoading = true;
      await api
        .put(`/jobs/${jobId}/position/${noOfOpenings}`)
        .then((res) => {
          if (res.status === 200) {
            if (this.clientAllocated.length > 0) {
              const client = this.clientAllocated.find(
                (client) => client.id === this.selectedClientId
              );
              const jobIndex = client.jobs.findIndex((job) => job.id === jobId);
              if (jobIndex !== -1) {
                client.jobs[jobIndex].openings = noOfOpenings;
              }
            }
          }
          toast.success("Openings Updated", {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addJD(jobId, data) {
      (this.isLoading = true),
        await api
          .put(`/jobs/${jobId}/job-description`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            return err;
          });
    },
    viewJD(jobDescriptionUrl) {
      const url = jobDescriptionUrl ? jobDescriptionUrl : null;
      const fileName = url?.substring(url.lastIndexOf("/") + 1);
      const fileExtension = fileName.split(".").pop();

      if (fileExtension === "pdf") {
        this.showJDPDf(url, "application/pdf");
      } else if (fileExtension === "docx") {
        this.showJDDoc(
          url,
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        );
      } else if (fileExtension === "doc") {
        this.showJDDoc(url, "application/msword");
      }
    },
    async showJDPDf(url, contextType) {
      await axios
        .get(url, {
          headers: {
            Accept: "*/*",
          },
          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], { type: contextType });

          const pdfURL = URL.createObjectURL(file);

          window.open(pdfURL, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async showJDDoc(url) {
      await axios
        .get(url, {
          headers: {
            Accept: "*/*",
          },

          responseType: "blob",
        })
        .then((response) => {
          const file = new Blob([response.data], {
            type: "application/msword",
          });

          const pdfURL = URL.createObjectURL(file);
          window.open(pdfURL, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getPendingJobApprovals() {
      this.isLoading = true;
      await api
        .get("/jobs/pending-approval")
        .then((res) => {
          this.jobApprovalPending = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async approveJob(jobId) {
      this.isLoading = true;
      this.confirmApproveJobDialog = false;
      await api
        .put(`/jobs/${jobId}/approve`)
        .then((res) => {
          const jobIndex = this.jobApprovalPending.findIndex(
            (job) => job.id === jobId
          );
          if (jobIndex !== -1) {
            this.jobApprovalPending.splice(jobIndex, 1);
          }
          toast.success(res.data, {
            autoClose: 1500,
            theme: "dark",
            position: toast.POSITION.TOP_CENTER,
            transition: toast.TRANSITIONS.ZOOM,
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async getJobsDailyAllocations(jobId, date) {
      this.isLoading = true;
      await api
        .get(`/jobs/${jobId}/daily-allocations?date=${date}`)
        .then((res) => {
          this.jobDailyAllocations = res.data;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
    async addJobsDailyAllocations(jobId, data) {
      this.isLoading = true;
      await api
        .post(`/jobs/${jobId}/daily-allocations`, data)
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data, {
              autoClose: 1500,
              theme: "dark",
              position: toast.POSITION.TOP_CENTER,
              transition: toast.TRANSITIONS.ZOOM,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          return err;
        });
    },
  },

  getters: {
    getClient() {
      const client = this.clientAllocated.map((client) => ({
        id: client.id,
        name: client.name,
      }));
      return [...new Set(client)];
    },
    getFormattedJobs() {
      if (this.selectedClient) {
        return this.clientAllocated
          .filter((client) => client.name === this.selectedClient)
          .flatMap((client) => {
            if (client.jobs) {
              if (this.search) {
                return client.jobs.filter((job) =>
                  job.title.toLowerCase().includes(this.search.toLowerCase())
                );
              } else {
                return client.jobs;
              }
            } else {
              return null;
            }
          });
      }
    },

    getClientContact() {
      const contacts = this.clientStore?.client?.contacts
        ?.filter((value) => value.active === true)
        ?.map((contact) => ({
          id: contact.id,
          name: contact.name,
        }));

      return [...new Set(contacts)];
    },
    getUserToAllocate() {
      const users = this.clientStore.client.allocations?.map((user) => ({
        id: user.userId,
        name: user.userName,
      }));
      return [...new Set(users)];
    },
    getJobApprovalPendingList() {
      let search = this.searchJobApproval.toLocaleLowerCase();
      if (search) {
        return this.jobApprovalPending.filter(
          (jobApproval) =>
            jobApproval.title.toLowerCase().includes(search) ||
            jobApproval.client.name.toLocaleLowerCase().includes(search)
        );
      } else {
        return this.jobApprovalPending;
      }
    },
    getDailyAllocationSenior(allocatedUsers) {
      return allocatedUsers?.map((allocation) => ({
        id: allocation.userId,
        fullName: allocation?.fullName,
      }));
    },
  },
});
